<template>
  <div>
    <Detail v-if="showDetail" />
    <v-card v-else class="overflow-hidden" elevation="0">
      <v-app-bar dense elevation="0">
        <v-select
          v-model="modelCategory"
          :items="itemsGenre"
          :label="$t('app.category')"
          :loading="loadingGenre"
          solo
          dense
          hide-details
          item-value="value"
          item-text="name"
          flat
          class="select-200 caption"
          clearable
          @change="onChangeGenre"
        />
        <v-tabs
          v-if="showTab"
          v-model="mainTab"
          align-with-title
          color="indigo"
          @change="mainTabChange"
        >
          <v-tab>{{ $t("app.home") }}</v-tab>
          <v-tab>{{ $t("app.most_popular") }}</v-tab>
          <v-tab>{{ $t("app.latest") }}</v-tab>
        </v-tabs>
      </v-app-bar>
      <v-sheet class="overflow-y-auto" max-height="600">
        <v-container style="height: 1000px;">
          <v-tabs-items v-if="showTab" v-model="mainTab" touchless>
            <v-tab-item>
              <v-row v-if="modelCategory">
                <v-col
                  v-for="item in listEbooks"
                  :key="item.id"
                  cols="12"
                  sm="4"
                  lg="2"
                >
                  <Card
                    :title="item.title"
                    :img="item.thumbnail"
                    :author="item.author"
                    :reads="item.reads"
                    :likes="item.likes"
                    :userLike="item.user_like"
                    :userRead="isUserRead(item)"
                    :idBook="item.id"
                    :wishlist="item.wishlist"
                    :userWishlist="item.user_wishlist"
                    :genre="item.genre"
                    @getData="getListEbooks()"
                  />
                </v-col>
                <v-col
                  v-if="listEbooks.length !== totalHomeEbook"
                  cols="12"
                  class="my-10 d-flex justify-center"
                >
                  <v-btn
                    :loading="loadingAllItem"
                    text
                    color="primary"
                    small
                    @click="viewMoreHome"
                  >
                    {{ $t("homepage.view_more") }}
                  </v-btn>
                </v-col>
              </v-row>
              <div v-else>
                <ListCard
                  v-for="(item, index) in itemsGenre"
                  :key="item.id"
                  :title="item.name"
                  :idGenre="item.value"
                  :class="index !== 0 && 'mt-10'"
                  @clickViewAll="viewAll(item.value)"
                />
              </div>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col
                  v-for="item in popularList"
                  :key="item.id"
                  cols="12"
                  sm="4"
                  lg="2"
                >
                  <Card
                    :title="item.title"
                    :img="item.thumbnail"
                    :author="item.author"
                    :reads="item.reads"
                    :likes="item.likes"
                    :userLike="item.user_like"
                    :userRead="isUserRead(item)"
                    :idBook="item.id"
                    :wishlist="item.wishlist"
                    :userWishlist="item.user_wishlist"
                    :genre="item.genre"
                    @getData="getEbookPopular()"
                  />
                </v-col>
                <v-col
                  v-if="popularList.length !== totalPopularEbook"
                  cols="12"
                  class="my-10 d-flex justify-center"
                >
                  <v-btn
                    :loading="loadingPopular"
                    text
                    color="primary"
                    small
                    @click="viewMoreHome"
                  >
                    {{ $t("homepage.view_more") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col
                  v-for="item in latestList"
                  :key="item.id"
                  cols="12"
                  sm="4"
                  lg="2"
                >
                  <Card
                    :title="item.title"
                    :img="item.thumbnail"
                    :author="item.author"
                    :reads="item.reads"
                    :likes="item.likes"
                    :userLike="item.user_like"
                    :userRead="isUserRead(item)"
                    :idBook="item.id"
                    :wishlist="item.wishlist"
                    :userWishlist="item.user_wishlist"
                    :genre="item.genre"
                    @getData="getLatestPopular()"
                  />
                </v-col>
                <v-col
                  v-if="latestList.length !== totalLatestEbook"
                  cols="12"
                  class="my-10 d-flex justify-center"
                >
                  <v-btn
                    :loading="loadingLatest"
                    text
                    color="primary"
                    small
                    @click="viewMoreHome"
                  >
                    {{ $t("homepage.view_more") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
          <IndexContent
            v-else
            :showAllItems="modelCategory !== null"
            :loading="loadingAllItem"
            :items="listEbooks"
            :itemsGenre="itemsGenre"
            :totalItems="totalHomeEbook"
            @getItems="getListEbooks()"
            @getMoreItems="viewMoreHome"
            @viewAll="viewAll"
          />
        </v-container>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import {
  genre,
  ebookPopular,
  ebookLatest,
  allList,
  inspirationList
} from "@/api/homepage/ebook";
import { mapGetters } from "vuex";

const fullPathIndex = [
  "/ebook",
  "/ebook?main=0",
  "/ebook?tab=0",
  "/inspiration",
  "/inspiration?main=0",
  "/inspiration?tab=0"
];
const limitItem = 12;

export default {
  props: {
    showTab: Boolean
  },
  components: {
    Card: () => import("./Card.vue"),
    ListCard: () => import("./ListCard.vue"),
    Detail: () => import("./Detail.vue"),
    IndexContent: () => import("./IndexContent.vue")
  },
  created() {
    const { query, name } = this.$route;
    this.isEbook = name === "Ebook";
    this.getGenre();
    if (this.showTab) {
      switch (query.main) {
        case "1":
          this.getEbookPopular();
          break;
        case "2":
          this.getLatestPopular();
          break;
      }
    }
  },
  computed: {
    ...mapGetters(["g_language"]),
    showDetail() {
      const { query } = this.$route;
      return query.detail !== null && query.detail !== undefined;
    }
  },
  data() {
    return {
      isEbook: false,
      listEbooks: [],
      popularList: [],
      latestList: [],
      modelCategory: null,
      itemsGenre: [],
      loadingGenre: false,
      loadingPopular: false,
      loadingAllItem: false,
      loadingLatest: false,
      mainTab: this.$route.query.main | 0,
      totalHomeEbook: 0,
      totalPopularEbook: 0,
      totalLatestEbook: 0,
      payloadPopular: {
        page: 1,
        limit: limitItem,
        sort: "id",
        order: "ASC",
        free: "",
        genre: ""
      },
      payloadLatest: {
        page: 1,
        limit: limitItem,
        sort: "id",
        order: "ASC",
        free: "",
        genre: ""
      },
      payloadMyEbook: {
        page: 1,
        limit: limitItem,
        sort: "id",
        order: "ASC"
      },
      payloadMyWishlist: {
        page: 1,
        limit: limitItem,
        sort: "id",
        order: "ASC"
      },
      payloadAllListEbook: {
        page: 1,
        limit: limitItem,
        sort: "id",
        order: "ASC",
        free: "",
        search: ""
      }
    };
  },
  methods: {
    isUserRead(item) {
      return this.isEbook ? item.user_read : item.user_view;
    },
    async getLatestPopular() {
      this.$Progress.start();
      this.loadingLatest = true;
      try {
        const { data } = await ebookLatest(this.payloadLatest);
        if (data.code) {
          this.latestList = data.data.data;
          this.totalLatestEbook = data.data.total;
        }
      } catch (error) {
        console.error("getEbookPopular()\n", error);
      } finally {
        this.$Progress.finish();
        this.loadingLatest = false;
      }
    },
    async getGenre() {
      this.loadingGenre = true;
      try {
        const { data } = await genre(this.isEbook);
        if (data.code) {
          this.itemsGenre = data.data.map(v => ({
            ...v,
            name: !this.isEbook
              ? v.value.toUpperCase()
              : this.g_language === "id"
              ? v.description_ind
              : v.description_eng
          }));
        }
      } catch (error) {
        console.error("getGenre()\n", error);
      } finally {
        this.loadingGenre = false;
      }
    },
    async getEbookPopular() {
      this.$Progress.start();
      this.loadingPopular = true;
      try {
        const { data } = await ebookPopular(this.payloadPopular);
        if (data.code) {
          this.popularList = data.data.data;
          this.totalPopularEbook = data.data.total;
        }
      } catch (error) {
        console.error("getEbookPopular()\n", error);
      } finally {
        this.$Progress.finish();
        this.loadingPopular = false;
      }
    },
    viewAll(value) {
      this.modelCategory = value;
      this.onChangeGenre(value);
    },
    viewMoreHome() {
      const { fullPath, query } = this.$route;
      if (fullPathIndex.some(v => v === fullPath)) {
        this.payloadAllListEbook.limit += limitItem;
        this.getListEbooks();
      } else if (parseInt(query.main) === 1) {
        this.payloadPopular.limit += limitItem;
        this.getEbookPopular();
      } else if (parseInt(query.main) === 2) {
        this.payloadLatest.limit += limitItem;
        this.getLatestPopular();
      }
    },
    async getListEbooks() {
      this.$Progress.start();
      this.loadingAllItem = true;
      try {
        const { data } = this.isEbook
          ? await allList(this.payloadAllListEbook)
          : await inspirationList(this.payloadAllListEbook);
        if (data.code) {
          this.listEbooks = this.isEbook
            ? data.data.data
            : data.data.data.map(v => ({ ...v, genre: v.category }));
          this.totalHomeEbook = data.data.total;
        }
      } catch (error) {
        console.error("getListEbooks()\n", error);
      } finally {
        this.$Progress.finish();
        this.loadingAllItem = false;
      }
    },
    mainTabChange(tab) {
      if (this.showTab) {
        switch (parseInt(tab)) {
          case 0:
            if (this.modelCategory) this.getListEbooks();
            break;
          case 1:
            this.getEbookPopular();
            break;
          case 2:
            this.getLatestPopular();
            break;
        }
        this.$router.push({ name: this.$route.name, query: { main: tab } });
      }
    },
    onChangeGenre(value) {
      const { fullPath, query } = this.$route;
      if (this.isEbook) this.payloadAllListEbook.genre = value;
      else this.payloadAllListEbook.category = value;
      this.payloadPopular.genre = value;
      this.payloadLatest.genre = value;
      if (fullPathIndex.some(v => v === fullPath) || !this.showTab)
        this.getListEbooks();
      else if (parseInt(query.main) === 1) this.getEbookPopular();
      else if (parseInt(query.main) === 2) this.getLatestPopular();
    }
  }
};
</script>
