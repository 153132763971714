import request from "@/utils/request";

export function allList(data) {
  return request({
    method: "post",
    url: "books/all_list",
    data: data
  });
}

export function genre(isEbook) {
  return request({
    method: "post",
    url: isEbook ? "book/genre" : "media/category"
  });
}

export function ebookPopular(data) {
  return request({
    method: "post",
    url: "books/popular_list",
    data: data
  });
}

export function ebookLatest(data) {
  return request({
    method: "post",
    url: "books/latest_list",
    data: data
  });
}

export function ebookMe(data) {
  return request({
    method: "post",
    url: "/book/list_bought",
    data: data
  });
}

export function myWishlist(data) {
  return request({
    method: "post",
    url: "/book/list_wishlist",
    data: data
  });
}

export function addWishlist(data) {
  return request({
    method: "post",
    url: "/book/wishlist",
    data: data
  });
}

export function likeEbook(data) {
  return request({
    method: "post",
    url: "/like_book",
    data: data
  });
}

export function likeInspiration(data) {
  return request({
    method: "post",
    url: "/inspirations/like_inspiration",
    data: data
  });
}

export function ebookById(data) {
  return request({
    method: "post",
    url: "/book_byid",
    data: data
  });
}

export function readBook(data) {
  return request({
    method: "post",
    url: "/read_book",
    data: data
  });
}

export function inspirationList(data) {
  return request({
    method: "post",
    url: "/inspirations/all_list",
    data: data
  });
}

export function inspirationById(data) {
  return request({
    method: "post",
    url: "/inspirations/inspiration_byid",
    data: data
  });
}
