<template>
  <v-card class="mx-auto mt-5" max-width="344" elevation="0">
    <v-img
      :src="img"
      height="200px"
      style="cursor: pointer;"
      @click="goToDetail()"
    />

    <div
      class="subtitle-1 font-weight-bold ml-1 mt-1"
      id="title"
      @click="goToDetail()"
    >
      {{ title }}
    </div>

    <div class="subtitle-2 ml-1 grey--text" id="subtitle">
      {{ author }}
    </div>

    <v-card-actions :class="showWishlist && 'd-flex justify-space-between'">
      <div :class="!showWishlist && 'mr-5'">
        <v-icon
          :color="userLike ? 'indigo' : 'grey'"
          :disabled="loadingAddLike"
          style="cursor: pointer"
          class="mr-1"
          @click="addToLike()"
        >
          mdi-thumb-up-outline
        </v-icon>
        <span class="caption">{{ likes }}</span>
      </div>
      <div>
        <v-icon :color="userRead ? 'indigo' : 'grey'" class="mr-1">
          mdi-eye-outline
        </v-icon>
        <span class="caption">{{ reads }}</span>
      </div>
      <div v-if="showWishlist">
        <v-icon
          :color="userWishlist ? 'indigo' : 'grey'"
          :disabled="loadingAddWishList"
          style="cursor: pointer"
          @click="addToWishlist()"
        >
          mdi-bookmark
        </v-icon>
        <span class="caption">{{ wishlist }}</span>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { addWishlist, likeEbook, likeInspiration } from "@/api/homepage/ebook";
import sign from "jwt-encode";

export default {
  props: {
    title: String,
    img: String,
    author: String,
    reads: Number,
    likes: Number,
    userLike: Number,
    userRead: Number,
    idBook: Number,
    wishlist: Number,
    userWishlist: Number,
    genre: String
  },
  computed: {
    showWishlist() {
      return this.wishlist !== undefined;
    }
  },
  created() {
    const { name } = this.$route;
    this.isEbook = name === "Ebook";
  },
  data() {
    return {
      isEbook: false,
      loadingAddWishList: false,
      loadingAddLike: false
    };
  },
  methods: {
    goToDetail() {
      const { name, query } = this.$route;
      this.$router.push({
        name: name,
        query: {
          ...query,
          detail: sign({ id: this.idBook, genre: this.genre }, "secret")
        }
      });
    },
    truncateText(desc) {
      return desc === null ? "" : desc.substr(0, 100);
    },
    async addToWishlist() {
      this.loadingAddWishList = true;
      try {
        const { data } = await addWishlist({ book: this.idBook });
        if (data.code) this.$emit("getData");
      } catch (error) {
        console.error("addToWishlist()\n", error);
      } finally {
        this.loadingAddWishList = false;
      }
    },
    async addToLike() {
      this.loadingAddLike = true;
      try {
        const { data } = this.isEbook
          ? await likeEbook({ book: this.idBook })
          : await likeInspiration({ inspiration: this.idBook });
        if (data.code) this.$emit("getData");
      } catch (error) {
        console.error("addToLike()\n", error);
      } finally {
        this.loadingAddLike = false;
      }
    }
  }
};
</script>
<style scoped>
#subtitle {
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
}
#title {
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
</style>
