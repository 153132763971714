<template>
  <v-row class="ma-5">
    <v-col cols="12">
      <div
        v-if="showContent"
        class="mb-5 font-weight-bold"
        style="cursor: pointer; width: 100px"
      >
        <v-btn
          @click="
            isEbook ? $router.go(-1) : $router.push({ name: 'Inspiration' })
          "
          depressed
        >
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("app.back") }}
        </v-btn>
      </div>
    </v-col>
    <v-col v-if="!showYoutube" cols="12" sm="3" xl="2">
      <v-img :src="dataEbook.thumbnail" />
    </v-col>
    <v-col
      cols="12"
      :sm="showYoutube ? 9 : 6"
      :xl="showYoutube ? 9 : 7"
      class="d-flex flex-column"
    >
      <div>
        <v-row>
          <v-col cols="12" class="font-weight-bold title">
            {{ dataEbook.title }}
          </v-col>
          <v-col
            :class="$vuetify.breakpoint.smAndDown && 'my-1'"
            cols="12"
            sm="6"
            class="grey--text"
          >
            {{ dataEbook.author }}
          </v-col>
          <v-col
            v-if="showContent"
            cols="12"
            sm="6"
            class="d-flex justify-lg-end"
          >
            <v-icon color="primary" class="mr-1">mdi-home-outline</v-icon>
            <span class="grey--text">{{ dataEbook.publisher }}</span>
          </v-col>
          <v-col cols="12" class="mt-5">
            <iframe
              v-if="showYoutube"
              :src="`https://www.youtube.com/embed/${dataEbook.content}`"
              width="560"
              height="315"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
            <audio v-else-if="dataEbook.category === 'podcast'" controls>
              <source :src="dataEbook.content" />
            </audio>
            <div v-else>
              <span v-if="isEbook" v-html="dataEbook.description" />
              <span v-else v-html="dataEbook.content" />
            </div>
          </v-col>
        </v-row>
      </div>
      <v-spacer />
      <div
        v-if="showContent"
        :class="$vuetify.breakpoint.smAndDown && 'mb-2'"
        class="d-md-flex mt-2"
      >
        <div>
          <v-icon
            :color="dataEbook.user_like ? 'indigo' : 'grey'"
            :disabled="loadingAddLike"
            style="cursor: pointer"
            class="mr-1"
            @click="addToLike(dataEbook.id)"
          >
            mdi-thumb-up-outline
          </v-icon>
          <span class="caption">{{ dataEbook.likes }}</span>
        </div>
        <div class="mx-5">
          <v-icon
            :color="isUserRead(dataEbook) ? 'indigo' : 'grey'"
            class="mr-1"
          >
            mdi-eye-outline
          </v-icon>
          <span class="caption">{{ dataEbook.reads }}</span>
        </div>
        <div v-if="isEbook">
          <v-icon
            :color="dataEbook.user_wishlist ? 'indigo' : 'grey'"
            :disabled="loadingAddWishList"
            style="cursor: pointer"
            @click="addToWishlist()"
          >
            mdi-bookmark
          </v-icon>
          <span class="caption">{{ dataEbook.wishlist }}</span>
        </div>
        <v-spacer />
        <v-btn
          v-if="showContent && isEbook"
          :loading="loadingRead"
          depressed
          dark
          color="gradient-primary"
          @click="gotoRead()"
        >
          {{ $t("app.read") }}
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12" sm="3" xl="3">
      <div class="font-weight-bold title">
        {{ $t("app.similar", { item: "E-Book" }) }}
      </div>
      <v-divider class="mb-2" width="150" />
      <div
        v-for="(item, index) in listEbooks"
        :key="item.id"
        :class="index === 1 && 'my-3'"
        class="d-flex flex-row"
      >
        <v-row dense>
          <v-col cols="12" sm="3">
            <v-img
              :src="item.thumbnail"
              height="100px"
              width="100px"
              style="cursor: pointer;"
              @click="goToDetail(item.id)"
            />
          </v-col>
          <v-col cols="12" sm="9" class="d-flex flex-column">
            <span
              class="font-weight-bold"
              style="cursor: pointer;"
              @click="goToDetail(item.id)"
            >
              {{ item.title }}
            </span>
            <span
              class="caption grey--text"
              style="cursor: pointer;"
              @click="goToDetail(item.id)"
            >
              {{ item.author }}
            </span>
            <v-spacer />
            <div>
              <v-icon
                :color="item.user_like ? 'indigo' : 'grey'"
                :disabled="loadingAddLike"
                small
                style="cursor: pointer"
                class="mr-1"
                @click="addToLike(item.id)"
              >
                mdi-thumb-up-outline
              </v-icon>
              <span class="caption mr-5">{{ item.likes }}</span>
              <v-icon
                :color="item.user_read ? 'indigo' : 'grey'"
                small
                class="mr-1"
              >
                mdi-eye-outline
              </v-icon>
              <span class="caption">{{ item.reads }}</span>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      max-width="1000px"
    >
      <v-card elevation="0">
        <v-card-title class="title font-weight-bold">
          {{ dataEbook.title }}
          <v-spacer />
          <v-btn icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-3" />
        <v-card-text>
          <ReadEbook :url="urlContent" />
        </v-card-text>
        <v-divider />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ebookById,
  addWishlist,
  likeEbook,
  allList,
  inspirationById,
  inspirationList,
  likeInspiration,
  readBook
} from "@/api/homepage/ebook";
import sign from "jwt-encode";

export default {
  components: {
    ReadEbook: () => import("./ReadEbook")
  },
  created() {
    const { name } = this.$route;
    this.isEbook = name === "Ebook";
    this.dataDetail = this.parseJwt(this.$route.query.detail);
    if (this.dataDetail.id) {
      this.getEbookById();
      this.getListEbooks();
    }
  },
  watch: {
    "$route.query.detail"(n) {
      this.dataDetail = this.parseJwt(n);
      if (this.dataDetail.id) {
        this.getEbookById();
        this.getListEbooks();
      }
    }
  },
  computed: {
    showYoutube() {
      const { content, category, type } = this.dataEbook;
      return (
        !this.isEbook &&
        content !== null &&
        category === "video" &&
        type === "youtube"
      );
    }
  },
  data() {
    return {
      dialog: false,
      loadingRead: false,
      isEbook: false,
      dataDetail: { id: null, genre: null },
      loadingAddLike: false,
      loadingAddWishList: false,
      showContent: false,
      loadingRelateItem: false,
      listEbooks: [],
      urlContent: "",
      dataEbook: {
        category: null,
        content: null,
        type: null,
        id: null,
        wishlist: null,
        user_wishlist: null,
        reads: null,
        like: null,
        user_read: null,
        user_like: null,
        description: null,
        publisher: null,
        author: null,
        title: null
      }
    };
  },
  methods: {
    closeDialog() {
      this.urlContent = "";
      this.dialog = false;
    },
    async gotoRead() {
      try {
        this.loadingRead = true;
        const { data } = await readBook({ id: this.dataDetail.id });
        if (data.code) {
          this.urlContent = data.data.file;
          this.dialog = true;
        } else {
          this.snackBar(true, this.$i18n.t("app.havent_bought_book"));
        }
      } catch (error) {
        console.error(error);
        this.dialog = false;
      } finally {
        this.loadingRead = false;
      }
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
      this.loadingSave = false;
    },
    isUserRead(item) {
      return this.isEbook ? item.user_read : item.user_view;
    },
    goToDetail(id) {
      const { name, query } = this.$route;
      const { genre } = this.dataDetail;
      this.$router.push({
        name: name,
        query: {
          ...query,
          detail: sign({ id: id, genre: genre }, "secret")
        }
      });
    },
    parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    async getListEbooks() {
      this.loadingRelateItem = true;
      let payload = {
        page: 1,
        limit: 3,
        sort: "id",
        order: "ASC",
        free: "",
        search: ""
      };

      const { genre } = this.dataDetail;
      if (this.isEbook) payload = { ...payload, genre: genre };
      else payload = { ...payload, category: genre };

      try {
        const { data } = this.isEbook
          ? await allList(payload)
          : await inspirationList(payload);
        if (data.code) {
          this.listEbooks = this.isEbook
            ? data.data.data
            : data.data.data.map(v => ({ ...v, genre: v.category }));
        }
      } catch (error) {
        console.error("getListEbooks()\n", error);
      } finally {
        this.loadingRelateItem = false;
      }
    },
    async addToWishlist() {
      this.loadingAddWishList = true;
      try {
        const { data } = await addWishlist({ book: this.dataEbook.id });
        if (data.code) this.getEbookById();
      } catch (error) {
        console.error("addToWishlist()\n", error);
      } finally {
        this.loadingAddWishList = false;
      }
    },
    async addToLike(id) {
      this.loadingAddLike = true;
      try {
        const { data } = this.isEbook
          ? await likeEbook({ book: id })
          : await likeInspiration({ inspiration: id });
        if (data.code) {
          this.getEbookById();
          this.getListEbooks();
        }
      } catch (error) {
        console.error("addToLike()\n", error);
      } finally {
        this.loadingAddLike = false;
      }
    },
    async getEbookById() {
      this.$Progress.start();
      const payload = { id: this.dataDetail.id };
      this.dataEbook = [];
      try {
        if (this.dataDetail.id) {
          const { data } = this.isEbook
            ? await ebookById(payload)
            : await inspirationById(payload);
          if (data.code) {
            this.dataEbook = this.isEbook ? data.data : data.data.inspiration;
            this.showContent = true;
          }
        }
      } catch (error) {
        console.error("getEbookById()\n", error);
      } finally {
        this.$Progress.finish();
      }
    }
  }
};
</script>
