<template>
  <v-row v-if="items.length !== 0">
    <v-col cols="12" class="d-flex align-center">
      <span class="title font-weight-bold">
        {{ title }}
      </span>
      <v-spacer />
      <v-btn depressed class="primary--text" @click="viewAll()">
        {{ $t("app.view_all") }}
        <v-icon class="ml-1" color="primary">
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </v-col>
    <v-col v-for="item in items" :key="item.id" cols="12" sm="4" lg="2">
      <Card
        :title="item.title"
        :img="item.thumbnail"
        :author="item.author"
        :reads="item.reads"
        :likes="item.likes"
        :userLike="item.user_like"
        :userRead="isUserRead(item)"
        :idBook="item.id"
        :wishlist="item.wishlist"
        :userWishlist="item.user_wishlist"
        :genre="item.genre"
        @getData="getItems(idGenre)"
      />
    </v-col>
  </v-row>
</template>

<script>
import Card from "./Card";
import { allList, inspirationList } from "@/api/homepage/ebook";

export default {
  props: {
    title: String,
    idGenre: String
  },
  components: {
    Card
  },
  watch: {
    idGenre: {
      immediate: true,
      handler(v) {
        if (v) {
          const { name } = this.$route;
          this.isEbook = name === "Ebook";
          this.getItems(v);
        }
      }
    }
  },
  data() {
    return {
      isEbook: false,
      items: []
    };
  },
  methods: {
    isUserRead(item) {
      return this.isEbook ? item.user_read : item.user_view;
    },
    viewAll() {
      this.$emit("clickViewAll");
    },
    async getItems(genre) {
      let payload = {
        page: 1,
        limit: 6,
        sort: "id",
        order: "ASC",
        free: "",
        search: ""
      };
      if (this.isEbook) payload = { ...payload, genre: genre };
      else payload = { ...payload, category: genre };
      try {
        const { data } = this.isEbook
          ? await allList(payload)
          : await inspirationList(payload);
        if (data.code) {
          this.items = this.isEbook
            ? data.data.data
            : data.data.data.map(v => ({ ...v, genre: v.category }));
        }
      } catch (error) {
        console.error("getItems(genre)\n", error);
      }
    }
  }
};
</script>
